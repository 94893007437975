/**
 * Batch creation pane (the parent that handles the high-level logic)
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer, useRef } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faCheckCircle,
  faEyeSlash,
  faHammer,
  faPlay,
  faStop,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';

// Import caccl
import CanvasCourse from 'caccl/types/CanvasCourse';

// Import dce-reactkit
import {
  visitServerEndpoint,
  LoadingSpinner,
  showFatalError,
  useForceRender,
} from 'dce-reactkit';

// Import shared types
import School from '../../../shared/types/from-server/School';
import GatherTag from '../../../shared/types/from-server/GatherTag';
import ProspectiveEventStatus from './types/ProspectiveEventStatus';

// Import shared constants
import IN_CASE_OF_EMERGENCY_SUFFIX from '../../../shared/constants/IN_CASE_OF_EMERGENCY_SUFFIX';

// Import helpers
import listProspectiveAndSkippedEvents from './helpers/listProspectiveAndSkippedEvents';
import genZoomMeetingTitle from '../../../shared/helpers/genZoomMeetingTitle';
import genEmptyEventObj from '../../../shared/helpers/genEmptyEventObj';
import genShareableLink from '../../../shared/helpers/genShareableLink';

// Import other components
import BatchEventCreationResultsModal from './BatchEventCreationResultsModal';
import BatchCreationFooter from './shared/BatchCreationFooter';
import BatchEventCreatorSetup from './BatchEventCreatorSetup';
import ProspectiveEventPreview from './ProspectiveEventPreview';
import BatchCreationProgress from './BatchCreationProgress';
import ProspectiveEvent from './helpers/ProspectiveEvent';
import CourseLounge from '../../../shared/types/from-server/stored/CourseLounge';
import CourseEvent from '../../../shared/types/from-server/stored/CourseEvent';
import CourseEventType from '../../../shared/types/from-server/stored/shared/CourseEventType';
import ZoomUserAccountStatus from '../../../shared/types/from-server/ZoomUserAccountStatus';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Choose google sheet, event type, etc.
  Setup = 'Setup',
  // Confirm the events that will be skipped
  ConfirmingSkippedEvents = 'ConfirmingSkippedEvents',
  // Confirm the events that will be created
  ConfirmingProspectiveEvents = 'ConfirmingProspectiveEvents',
  // Currently creating events
  Running = 'Running',
  // Finished (halted or done) creating events
  Ended = 'Ended',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.Setup,
    // The email of the robot account
    robotEmail: string,
  }
  | {
    // Current view
    view: View.ConfirmingSkippedEvents,
    // List of skipped events
    skippedEvents: ProspectiveEvent[],
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // If true, show the skipped events that already have results
    showSkippedThatAlreadyHaveResults: boolean,
    // Current calendar year
    year: number,
  }
  | {
    // Current view
    view: View.ConfirmingProspectiveEvents,
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // Current calendar year
    year: number,
  }
  | {
    // Current view
    view: View.Running,
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // The id of the event that is current being created
    idOfEventBeingCreated?: number,
    // Current calendar year
    year: number,
  }
  | {
    // Current view
    view: View.Ended,
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // If true, show the results modal
    showResultsPopup: boolean,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the loading indicator
  ShowLoadingSpinner = 'ShowLoadingSpinner',
  // Show the setup page
  ShowSetup = 'ShowSetup',
  // Show the skipped events confirmation page
  ShowConfirmingSkippedEvents = 'ShowConfirmingSkippedEvents',
  // Show skipped events that already have results
  ShowSkippedWithResults = 'ShowSkippedWithResults',
  // Show the prospective events confirmation page
  ShowConfirmingProspectiveEvents = 'ShowConfirmingProspectiveEvents',
  // Show the running page
  ShowRunning = 'ShowRunning',
  // Update an event in the list
  UpdateProspectiveEvent = 'UpdateProspectiveEvent',
  // Change which event is being created
  ChangeEventBeingCreated = 'ChangeEventBeingCreated',
  // Show the ended page
  ShowEnded = 'ShowEnded',
  // Hide results modal
  HideResultsModal = 'HideResultsModal',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ShowLoadingSpinner,
  }
  | {
    // Action type
    type: ActionType.ShowSetup,
    // The email of the robot account
    robotEmail: string,
  }
  | {
    // Action type
    type: ActionType.ShowConfirmingSkippedEvents,
    // List of skipped events
    skippedEvents: ProspectiveEvent[],
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // The calendar year of the courses to create
    year: number,
  }
  | {
    // Action type
    type: ActionType.ShowSkippedWithResults,
  }
  | {
    // Action type
    type: ActionType.ShowConfirmingProspectiveEvents,
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // The calendar year of the courses to create
    year: number,
  }
  | {
    // Action type
    type: ActionType.ShowRunning,
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
    // The calendar year of the courses to create
    year: number,
  }
  | {
    // Action type
    type: ActionType.ChangeEventBeingCreated,
    // The id of the event that is current being created
    idOfEventBeingCreated?: number,
  }
  | {
    // Action type
    type: ActionType.UpdateProspectiveEvent
    // The updated event
    updatedEvent: ProspectiveEvent,
  }
  | {
    // Action type
    type: ActionType.ShowEnded,
    // List of prospective events
    prospectiveEvents: ProspectiveEvent[],
  }
  | {
    // Action type
    type: ActionType.HideResultsModal,
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowLoadingSpinner: {
      return {
        ...state,
        view: View.Loading,
      };
    }
    case ActionType.ShowSetup: {
      return {
        ...state,
        view: View.Setup,
        robotEmail: action.robotEmail,
      };
    }
    case ActionType.ShowConfirmingSkippedEvents: {
      return {
        ...state,
        view: View.ConfirmingSkippedEvents,
        skippedEvents: action.skippedEvents,
        prospectiveEvents: action.prospectiveEvents,
        showSkippedThatAlreadyHaveResults: false,
        year: action.year,
      };
    }
    case ActionType.ShowSkippedWithResults: {
      // Make sure view is confirming skipped events
      if (state.view !== View.ConfirmingSkippedEvents) {
        return state;
      }
      return {
        ...state,
        showSkippedThatAlreadyHaveResults: true,
      };
    }
    case ActionType.ShowConfirmingProspectiveEvents: {
      return {
        ...state,
        view: View.ConfirmingProspectiveEvents,
        prospectiveEvents: action.prospectiveEvents,
        year: action.year,
      };
    }
    case ActionType.ShowRunning: {
      return {
        ...state,
        view: View.Running,
        prospectiveEvents: action.prospectiveEvents,
        idOfEventBeingCreated: undefined,
        year: action.year,
      };
    }
    case ActionType.ChangeEventBeingCreated: {
      // Make sure view is running
      if (state.view !== View.Running) {
        return state;
      }
      return {
        ...state,
        idOfEventBeingCreated: action.idOfEventBeingCreated,
      };
    }
    case ActionType.UpdateProspectiveEvent: {
      // Make sure view is running
      if (state.view !== View.Running) {
        return state;
      }
      return {
        ...state,
        prospectiveEvents: state.prospectiveEvents.map((event) => {
          if (event.getID() === action.updatedEvent.getID()) {
            return action.updatedEvent;
          }
          return event;
        }),
      };
    }
    case ActionType.ShowEnded: {
      return {
        ...state,
        view: View.Ended,
        prospectiveEvents: action.prospectiveEvents,
        showResultsPopup: true,
      };
    }
    case ActionType.HideResultsModal: {
      // Skip if not in the right view
      if (state.view !== View.Ended) {
        return state;
      }
      return {
        ...state,
        showResultsPopup: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BatchEventCreator: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /* -------------- Refs -------------- */

  // Initialize refs
  const halted = useRef<boolean>(false);

  /* ---------- Force Render ---------- */

  const forceRender = useForceRender(useReducer);

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Get the result numbers
   * @author Gabe Abrams
   * @returns results in the form
   *   { numSuccessful, numFailed, numSkipped }
   */
  const getResults = () => {
    // Make sure we have the right view
    if (view !== View.Ended) {
      return {
        numSuccessful: 0,
        numWarning: 0,
        numFailed: 0,
        numSkipped: 0,
      };
    }

    // Destructure state
    const { prospectiveEvents } = state;

    // Count the number of successful events
    const numSuccessful = (
      prospectiveEvents
        .filter((event) => {
          return (event.getStatus() === ProspectiveEventStatus.Successful);
        })
        .length
    );

    // Count the number of events created with warnings
    const numWarning = (
      prospectiveEvents
        .filter((event) => {
          return (
            event.getStatus() === ProspectiveEventStatus.CreatedWithWarning
          );
        })
        .length
    );

    // Count the number of failed events
    const numFailed = (
      prospectiveEvents
        .filter((event) => {
          return (event.getStatus() === ProspectiveEventStatus.Failed);
        })
        .length
    );

    // Calculate the number of skipped events
    const numSkipped = (
      prospectiveEvents.length
      - numSuccessful
      - numWarning
      - numFailed
    );

    // Return the results object
    return {
      numSuccessful,
      numWarning,
      numFailed,
      numSkipped,
    };
  };

  /**
   * Load the list of prospective and skipped events
   * @author Gabe Abrams
   * @param googleSheetURL the URL of the google sheet
   * @param year the calendar year for the courses
   */
  const load = async (
    googleSheetURL: string,
    year: number,
  ) => {
    // Show loading indicator
    dispatch({
      type: ActionType.ShowLoadingSpinner,
    });

    try {
      // Load the list of skipped and prospective events
      const {
        prospectiveEvents,
        skippedEvents,
      } = await listProspectiveAndSkippedEvents(googleSheetURL);

      // Update state
      if (skippedEvents.length > 0) {
        dispatch({
          type: ActionType.ShowConfirmingSkippedEvents,
          skippedEvents,
          prospectiveEvents,
          year,
        });
      } else {
        dispatch({
          type: ActionType.ShowConfirmingProspectiveEvents,
          prospectiveEvents,
          year,
        });
      }
    } catch (err) {
      // Show the error
      return showFatalError(err);
    }
  };

  /**
   * Create an event
   * @author Gabe Abrams
   */
  const createEvent = async (prospectiveEvent: ProspectiveEvent) => {
    // Skip if in wrong view
    if (
      state.view !== View.Running
      && state.view !== View.ConfirmingProspectiveEvents
      && state.view !== View.ConfirmingSkippedEvents
    ) {
      return;
    }

    /*----------------------------------------*/
    /*          Set State to Pending          */
    /*----------------------------------------*/

    prospectiveEvent.setResults(ProspectiveEventStatus.Pending);
    try {
      await prospectiveEvent.save();
    } catch (err) {
      // Could not write
      const newErr = new Error('We couldn\'t write to the Google Sheet. Please make sure the Gather robot is an editor.');
      showFatalError(newErr);
      return;
    }

    /*----------------------------------------*/
    /*            Helper Functions            */
    /*----------------------------------------*/

    /**
     * Update the prospective event in the state
     * @author Gabe Abrams
     */
    const updateEventInState = async () => {
      // Update the state
      dispatch({
        type: ActionType.UpdateProspectiveEvent,
        updatedEvent: prospectiveEvent,
      });
    };

    /**
     * Set the status of the prospective event to failed, including an
     *   error message
     * @author Gabe Abrams
     * @param errorMessage the error message to attach to the
     *   prospective event
     */
    const setStatusToFailed = async (errorMessage: string) => {
      // Update the prospective event
      prospectiveEvent.setResults(
        ProspectiveEventStatus.Failed,
        errorMessage,
      );
      try {
        await prospectiveEvent.save();
      } catch (err) {
        throw new Error('Oops! We couldn\'t write results to the Google Sheet. Please make sure the Gather robot is an editor.');
      }
    };

    /*----------------------------------------*/
    /*                  Logic                 */
    /*----------------------------------------*/

    // Get info from state
    const {
      year,
    } = state;

    // Create a name for the event
    let eventName = prospectiveEvent.getEventName();

    // Append emergency suffix
    if (prospectiveEvent.isEmergencyEvent()) {
      eventName += ` ${IN_CASE_OF_EMERGENCY_SUFFIX}`;
    }

    // Get the Canvas Course ID
    let courseId: number;
    let crn: string;
    try {
      // Get the CRN for the event
      crn = prospectiveEvent.getCRN();

      // Look up the Canvas courseId
      courseId = await visitServerEndpoint({
        path: `/api/admin/crns/${crn}/years/${year}`,
        method: 'GET',
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't find the Canvas course. ${(err as any).message}`);
      await updateEventInState();
      return;
    }

    // Get the course info object
    let course: CanvasCourse;
    try {
      course = await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/canvas_object`,
        method: 'GET',
        params: {
          includeTerm: true, // Get the term so we can add it to the zoom title
        },
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't get info on the course (${(err as any).message})`);
      await updateEventInState();
      return;
    }

    // Create a Zoom meeting/webinar
    let zoomId: number;
    let password: number;
    let joinURL: string;
    let hostId: string;
    try {
      // Get the Zoom settings/metadata
      const waitingRoomOn = prospectiveEvent.isWaitingRoomOn();
      const autoRecordOn = prospectiveEvent.isAutoRecordOn();
      const hostEmail = prospectiveEvent.getHostEmail();
      const hostVideoDisabled = prospectiveEvent.isHostVideoDisabled();
      const isWebinar = prospectiveEvent.getIsWebinar();

      // Create a Zoom meeting/webinar title
      const zoomTitle = await genZoomMeetingTitle({
        eventName,
        crn: Number.parseInt(crn, 10),
        courseName: (course.course_code || course.name),
        termName: (course?.term?.name ?? 'No Term'),
      });

      // Create the Zoom meeting or webinar
      if (isWebinar) {
        const results = await visitServerEndpoint({
          path: `/api/admin/courses/${courseId}/webinars`,
          method: 'POST',
          params: {
            hostEmail,
            autoRecordOn,
            hostVideoDisabled,
            title: zoomTitle,
          },
        });

        // Destructure results
        ({
          zoomId,
          password,
          hostId,
        } = results);
        joinURL = results.startURL;
      } else {
        const response = await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/meetings`,
          method: 'POST',
          params: {
            hostEmail,
            waitingRoomOn,
            autoRecordOn,
            hostVideoDisabled,
            school: School.DCE,
            title: zoomTitle,
          },
        });

        // Check for "needs to claim"
        if (response?.status === ZoomUserAccountStatus.NeedsToClaim) {
          await setStatusToFailed('We couldn\'t create a Zoom meeting because the host needs to claim their Zoom account.');
          await updateEventInState();
          return;
        }

        // Doesn't need to claim. Success!
        ({
          zoomId,
          password,
          joinURL,
          hostId,
        } = response);
      }
    } catch (err) {
      await setStatusToFailed(`We couldn't create a Zoom ${prospectiveEvent.getIsWebinar() ? 'webinar' : 'meeting'} (${(err as any).message})`);
      await updateEventInState();
      return;
    }

    // Create a lounge
    const ensureLoungeExists = prospectiveEvent.isEnsuringLoungeExists();
    if (ensureLoungeExists) {
      try {
        // Check if the lounge exists
        const lounges: CourseLounge[] = await visitServerEndpoint({
          path: `/api/courses/${courseId}/lounges`,
          method: 'GET',
          params: {
            includeArchived: true,
          },
        });

        // Only create a lounge if none exist
        const allLoungesArchived = lounges.every((lounge) => {
          return lounge.archived;
        });
        if (allLoungesArchived) {
          // Create the lounge Zoom name
          const loungeZoomName = await genZoomMeetingTitle({
            courseId,
            eventName: 'Gather Study Lounge',
            courseName: (course.course_code || course.name),
            termName: (course?.term?.name ?? 'No Term'),
          });

          // Figure out next lounge id
          let highestLoungeNumber = 1;
          lounges.forEach((lounge) => {
            const loungeNumber = Number.parseInt(
              lounge.loungeId.substring(1),
              10,
            );
            if (loungeNumber > highestLoungeNumber) {
              highestLoungeNumber = loungeNumber;
            }
          });
          const nextLoungeId = `L${highestLoungeNumber + 1}`;

          // Create the lounge
          await visitServerEndpoint({
            path: `/api/admin/courses/${courseId}/lounges`,
            method: 'POST',
            params: {
              name: 'Study Lounge',
              loungeId: nextLoungeId,
              loungeZoomName,
              hostEmail: prospectiveEvent.getHostEmail(),
              school: School.DCE,
            },
          });
        }
      } catch (err) {
        await setStatusToFailed(`We couldn't create a study lounge because an error occurred: ${(err as any).message}`);
        await updateEventInState();
        return;
      }
    }

    // Get the IHID for the meeting
    // > Load the list of other events in the course
    let existingEvents: CourseEvent[];
    try {
      existingEvents = await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/events`,
        method: 'GET',
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't get the list of existing events in the course (${(err as any).message})`);
      await updateEventInState();
      return;
    }

    // Create the event object
    const event = genEmptyEventObj(courseId, existingEvents);
    event.name = eventName;
    event.type = (
      prospectiveEvent.getIsSectionEvent()
        ? CourseEventType.Section
        : CourseEventType.Class
    );
    event.currentZoomId = zoomId;
    event.currentZoomHost = hostId;
    event.openZoomLink = joinURL.replace('/s/', '/j/');
    event.lockAutoRecordSetting = prospectiveEvent.isLockAutoRecordSettingOn();
    event.lockZoomToggle = true; // always true for now from batch create
    event.banDCEStudents = prospectiveEvent.isDCEBanOn();
    event.banFASStudents = prospectiveEvent.isFASBanOn();
    event.isWebinar = !!prospectiveEvent.getIsWebinar();

    // Validate the join url
    const validJoinURL = (joinURL.indexOf('pwd=') >= 0);

    // Set up IC
    const icFeatureFlagMap = prospectiveEvent.getICFeatureFlagMap();
    if (icFeatureFlagMap) {
      // Set up IC
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/ic/default-feature-flag-map`,
        method: 'POST',
        params: {
          defaultFeatureFlagMap: icFeatureFlagMap,
        },
      });
    }

    // Update the prospective event
    // > Add values
    prospectiveEvent.setOpenZoomLink(joinURL);
    prospectiveEvent.setGatherLink(genShareableLink(courseId, event.ihid));
    prospectiveEvent.setZoomPassword(String(password));
    prospectiveEvent.setZoomId(String(zoomId));
    prospectiveEvent.setCanvasLink(`https://canvas.harvard.edu/courses/${courseId}`);
    // > Add result
    if (validJoinURL) {
      prospectiveEvent.setResults(ProspectiveEventStatus.Successful);
    } else {
      prospectiveEvent.setResults(
        ProspectiveEventStatus.CreatedWithWarning,
        `Zoom ${prospectiveEvent.getIsWebinar() ? 'webinar' : 'meeting'} does not have a password embedded URL!`,
      );
    }
    // > Save to google sheet
    try {
      await prospectiveEvent.save();
    } catch (err) {
      await setStatusToFailed(`We couldn't save the results to the google sheet (but the event was created). Error: ${(err as any).message}`);
      await updateEventInState();
      return;
    }

    // Store the event in the list (DB)
    try {
      // Save via the ttm API
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'POST',
        params: {
          event,
        },
      });
    } catch (err) {
      await setStatusToFailed(`We couldn't save the event to the course event list (${(err as any).message})`);
      await updateEventInState();
      return;
    }

    // Update the event
    return updateEventInState();
  };

  /**
   * Start the batch creation process
   * @author Gabe Abrams
   */
  const start = async () => {
    // Reset halted
    halted.current = false;

    // Skip if in wrong view
    if (state.view !== View.ConfirmingProspectiveEvents) {
      return;
    }

    // Destructure state
    const {
      prospectiveEvents,
      year,
    } = state;

    // Update the state
    dispatch({
      type: ActionType.ShowRunning,
      prospectiveEvents,
      year,
    });

    // Go through the prospective events and create them one by one
    for (let i = 0; i < prospectiveEvents.length; i++) {
      // Check if halted
      if (halted.current) {
        // Stop now
        return dispatch({
          type: ActionType.ShowEnded,
          prospectiveEvents,
        });
      }

      // Update the state
      dispatch({
        type: ActionType.ChangeEventBeingCreated,
        idOfEventBeingCreated: prospectiveEvents[i].getID(),
      });

      // Create the event
      try {
        await createEvent(prospectiveEvents[i]);
      } catch (err) {
        // An unrecoverable error occurred
        return showFatalError(err);
      }

      // Done! No event being created
      dispatch({
        type: ActionType.ChangeEventBeingCreated,
        idOfEventBeingCreated: undefined,
      });
    }

    // Finished!
    dispatch({
      type: ActionType.ShowEnded,
      prospectiveEvents,
    });
  };

  /**
   * Halt the batch creation process
   * @author Gabe Abrams
   */
  const halt = () => {
    halted.current = true;
    forceRender();
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Reset halted
        halted.current = false;

        // Load the robot email
        try {
          const robotEmail = await visitServerEndpoint({
            path: '/api/admin/sheets/robot/email',
            method: 'GET',
          });

          // Update state
          dispatch({
            type: ActionType.ShowSetup,
            robotEmail,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        halted.current = true;
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ----- Batch Creation Results ----- */

  if (view === View.Ended && state.showResultsPopup) {
    // Get the results
    const {
      numSuccessful,
      numWarning,
      numFailed,
      numSkipped,
    } = getResults();

    // Create the modal
    modal = (
      <BatchEventCreationResultsModal
        halted={halted.current}
        numSuccessful={numSuccessful}
        numWarning={numWarning}
        numFailed={numFailed}
        numSkipped={numSkipped}
        onClose={() => {
          // Close the modal
          dispatch({
            type: ActionType.HideResultsModal,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Just return loading indicator
    return (
      <LoadingSpinner />
    );
  }

  /* -------------- Setup ------------- */

  if (view === View.Setup) {
    // Destructure state
    const { robotEmail } = state;

    // Create body
    body = (
      <BatchEventCreatorSetup
        robotEmail={robotEmail}
        onDone={(googleSheetURL, year) => {
          // Start the loading process
          load(googleSheetURL, year);
        }}
      />
    );
  }

  /* ------------- Confirm ------------ */

  if (
    view === View.ConfirmingSkippedEvents
    || view === View.ConfirmingProspectiveEvents
  ) {
    // Destructure state
    const { prospectiveEvents } = state;

    // Create a subheading
    let subheading: string | undefined;
    let subheadingIcon: IconDefinition | undefined;
    if (view === View.ConfirmingSkippedEvents) {
      subheading = 'Events to skip:';
    } else if (view === View.ConfirmingProspectiveEvents) {
      subheading = 'Events to create:';
    } else if (view === View.Running) {
      subheading = 'Working! Don\'t edit the sheet or let the machine sleep.';
      subheadingIcon = faHammer;
    } else if (view === View.Ended) {
      subheading = (
        halted.current
          ? 'Halted. Results:'
          : 'All finished! Results:'
      );
      subheadingIcon = (
        halted.current
          ? faStopCircle
          : faCheckCircle
      );
    }

    // Booleans for state
    const confirming = (
      view === View.ConfirmingSkippedEvents
      || view === View.ConfirmingProspectiveEvents
    );

    // Create a list of event previews
    let eventPreviews: React.ReactNode[] = [];
    if (confirming) {
      // Confirmation previews

      // Get the list of events based on the type we're confirming
      let events: ProspectiveEvent[];
      if (view === View.ConfirmingSkippedEvents) {
        // Destructure state
        const {
          skippedEvents,
          showSkippedThatAlreadyHaveResults,
        } = state;

        // Confirming skipped events
        events = (
          skippedEvents
            // Filter events that already have results (if not being shown)
            .filter((event) => {
              return (
                showSkippedThatAlreadyHaveResults
                || !event.alreadyHasResults()
              );
            })
        );
      } else {
        // Confirming prospective events
        events = prospectiveEvents;
      }

      // Create event previews
      eventPreviews = events.map((event) => {
        return (
          <ProspectiveEventPreview
            key={event.getID()}
            event={event}
            showSettings={view === View.ConfirmingProspectiveEvents}
          />
        );
      });

      // Add a "show more" button if not showing events with results
      // (only if there is at least one that has results)
      if (
        // We are currently confirming skipped events
        view === View.ConfirmingSkippedEvents
        // Events with results are hidden
        && !state.showSkippedThatAlreadyHaveResults
        // There is at least one event that has results
        && state.skippedEvents.some((event) => {
          return event.alreadyHasResults();
        })
      ) {
        eventPreviews.push(
          <div key="show-more">
            <div className={`alert alert-warning text-dark mt-${eventPreviews.length > 0 ? '3' : '0'} mb-1`}>
              {/* Explanation */}
              <h5 className="m-0">
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="me-2"
                />
                Events with Results Hidden
              </h5>
              <div className="mb-2">
                We hid events that already have text in one of the results
                columns, marked by the
                {' '}
                {GatherTag.Write}
                {' '}
                tag.
              </div>

              {/*  Show More Button */}
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                aria-label="show events that were already created"
                onClick={() => {
                  // Update state
                  dispatch({
                    type: ActionType.ShowSkippedWithResults,
                  });
                }}
              >
                Show Events with Results
              </button>
            </div>
          </div>,
        );
      }
    } else if (view !== View.Running && view !== View.Ended) {
      // Running/ended status previews
      eventPreviews = prospectiveEvents.map((event) => {
        return (
          <ProspectiveEventPreview
            key={event.getID()}
            event={event}
            showSettings={false}
          />
        );
      });
    }

    /* --------------------------- Footer --------------------------- */

    // Create a footer
    // NOTE: for SETUP, footer is already added by BatchEventCreatorSetup
    let footer: React.ReactNode;
    if (view === View.ConfirmingSkippedEvents) {
      // Destructure state
      const {
        year,
      } = state;

      footer = (
        <BatchCreationFooter
          continueButton={{
            onClick: () => {
              // Update state
              dispatch({
                type: ActionType.ShowConfirmingProspectiveEvents,
                prospectiveEvents,
                year,
              });
            },
          }}
        />
      );
    } else if (view === View.ConfirmingProspectiveEvents) {
      footer = (
        <BatchCreationFooter
          continueButton={{
            onClick: start,
            label: 'Start Batch Creation',
            icon: faPlay,
          }}
        />
      );
    }

    /* ---------------------- Assemble Full UI ---------------------- */

    // Assemble body
    body = (
      <div>
        {/* Content */}
        <div>
          {/* Subtitle */}
          <h3 className="mb-3 text-start fw-bold">
            {(subheadingIcon && (
              <FontAwesomeIcon
                icon={subheadingIcon}
                className="me-2"
              />
            ))}
            {subheading}
          </h3>

          {/* List of events */}
          <div
            style={{
              maxHeight: '30rem',
              overflowY: 'auto', // Scroll if needed
            }}
          >
            {eventPreviews}
          </div>
        </div>

        {/* Footer */}
        {footer}
      </div>
    );
  }

  /* ---------- Running/Ended --------- */

  if (view === View.Running || view === View.Ended) {
    // Destructure state
    const {
      prospectiveEvents,
    } = state;

    if (view === View.Running) {
      // Count the number of finished events
      const numFinished = (
        prospectiveEvents
          // Filter out any pending events
          .filter((event) => {
            return (event.getStatus() !== ProspectiveEventStatus.Pending);
          })
          // Count the number of events
          .length
      );

      // Create the content
      body = (
        <div>
          {/* Progress Bar */}
          <BatchCreationProgress
            numFinished={numFinished}
            numTasks={prospectiveEvents.length}
            ended={false}
          />

          {/* Halt Button */}
          <div>
            <button
              type="button"
              className={`btn btn-lg btn-${halted.current ? 'secondary active' : 'dark'}`}
              aria-label="halt the batch create process"
              onClick={halt}
              disabled={halted.current}
            >
              {
                halted.current
                  ? 'Halting...'
                  : (
                    <span>
                      <FontAwesomeIcon
                        icon={faStop}
                        className="me-2"
                      />
                      Halt Event Creation
                    </span>
                  )
              }
            </button>
          </div>
        </div>
      );
    } else if (view === View.Ended) {
      // Get the results
      const {
        numSuccessful,
        numWarning,
        numFailed,
        numSkipped,
      } = getResults();

      // Create the content
      body = (
        <div>
          {/* Title */}
          <h3>
            Finished!
          </h3>

          {/* Results Message */}
          <div id="BatchEventCreator-results-summary-text">
            <strong>
              Results:
            </strong>
            {' '}
            {`${numSuccessful} event${numSuccessful === 1 ? '' : 's'} created successfully, ${numWarning} created with warnings, and ${numFailed} failed.`}
          </div>

          {/* Skipped Items Message */}
          {numSkipped > 0 && (
            <div>
              {`Also, ${numSkipped} event${numSkipped === 1 ? ' was' : 's were'} skipped because the process was halted`}
            </div>
          )}
        </div>
      );
    }
  }

  /* ------------ No Events ----------- */

  // No prospective events to create
  if (
    view === View.ConfirmingProspectiveEvents
    && state.prospectiveEvents.length === 0
  ) {
    body = (
      <div className="alert alert-warning text-dark m-0">
        <strong>
          Oops!
        </strong>
        &nbsp;
        There are no events to batch create.
        Please check your spreadsheet and try again.
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="BatchEventCreator-container">
      {modal}

      {/* Header */}
      <div className="mb-3">
        <h1 className="text-white fw-bold">
          Batch Setup
        </h1>
      </div>

      <div className="alert alert-light text-dark">
        {body}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BatchEventCreator;
