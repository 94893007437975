// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

// Import others types
import ColumnHeader from '../types/ColumnHeader';

// Import shared constants
import IN_CASE_OF_EMERGENCY_SUFFIX from '../../../../shared/constants/IN_CASE_OF_EMERGENCY_SUFFIX';

// Create checkmark examples
const checkedExample = (
  <FontAwesomeIcon icon={faCheckSquare} />
);
const uncheckedExample = (
  <FontAwesomeIcon icon={faSquare} />
);

/**
 * List of column headers
 * @author Gabe Abrams
 */
const COLUMN_HEADERS: ColumnHeader[] = [
  /* ------------------- Required Input Columns ------------------- */
  {
    prop: 'batchCreate',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: true,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Batch Create Class?',
    ],
    description: 'Rows with a checked checkbox here will be read by Gather.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'crn',
    type: 'number',
    allowSpaces: undefined, // Not relevant to numbers
    required: true,
    defaultValue: undefined, // No default value! Cells cannot be empty.
    isWriteColumn: false,
    names: [
      'CRN',
    ],
    description: 'The course CRN.',
    examples: [13856, 39482],
  },
  {
    prop: 'courseCode',
    type: 'string',
    allowSpaces: true,
    required: true,
    defaultValue: undefined, // No default value! Cells cannot be empty.
    isWriteColumn: false,
    names: [
      'Course', // Primary
    ],
    description: 'A short text description of the course.',
    examples: ['CS 900 Algorithms', 'ENGL 100 Creative Writing'],
  },
  {
    prop: 'hostEmail',
    type: 'string',
    allowSpaces: false,
    required: true,
    defaultValue: undefined, // No default value! Cells cannot be empty.
    isWriteColumn: false,
    names: [
      'Class Zoom Host Email', // Primary
    ],
    description: 'The Zoom account email for the user who will own the event.',
    examples: ['teacher@harvard.edu', 'ta@fas.harvard.edu'],
  },

  /* ------------------ Optional Columns ------------------ */

  {
    prop: 'isLockAutoRecordSettingOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Lock Auto Record Setting for Class?', // Primary
    ],
    description: 'If checked, auto record setting will be editable by non-admins.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'isDCEBanOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Ban DCE Students from Class?', // Primary
    ],
    description: 'If checked, DCE students will not be allowed to join the event.',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isFASBanOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Ban FAS Students from Class?', // Primary
    ],
    description: 'If checked, FAS students will not be allowed to join the event.',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isAutoRecordOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Auto Record Class?', // Primary
    ],
    description: 'If checked, auto record will be turned on.',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isWaitingRoomOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Class Waiting Room On?', // Primary
    ],
    description: 'If checked, waiting room will be turned on (webinars cannot have waiting rooms).',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isEmergencyEvent',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Class is Emergency Event?', // Primary
    ],
    description: `If checked, event title will get an "${IN_CASE_OF_EMERGENCY_SUFFIX}" suffix.`,
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'ensureLoungeExists',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Ensure Lounge Exists?', // Primary
    ],
    description: 'If checked and no study lounge exists, create a study lounge under same Zoom host.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'hostVideoDisabled',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Force Host Video Off?', // Primary
    ],
    description: 'If checked, host/panelist video will be disabled. Otherwise, user setting will be used.',
    examples: [uncheckedExample, checkedExample],
  },
  {
    prop: 'customEventName',
    type: 'string',
    allowSpaces: true,
    required: false,
    defaultValue: '',
    isWriteColumn: false,
    names: [
      'Custom Event Name', // Primary
    ],
    description: 'Custom text for the event name, defaults to "Class".',
    examples: ['Class: HELIX Live Stream', 'Class: HELIX Classroom'],
  },
  {
    prop: 'isWebinar',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Class is a Webinar?', // Primary
    ],
    description: 'If checked, the event will be created as a webinar. Otherwise, created as a meeting.',
    examples: [uncheckedExample, checkedExample],
  },
  {
    prop: 'isICEnabled',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'IC Enabled?', // Primary
    ],
    description: 'If checked, IC will be enabled course-wide.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'isICPollDisabled',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'IC Poll Disabled?', // Primary
    ],
    description: 'If checked, IC Poll activities will be disabled course-wide.',
    examples: [uncheckedExample, checkedExample],
  },
  {
    prop: 'isICRespondDisabled',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'IC Respond Disabled?', // Primary
    ],
    description: 'If checked, IC Respond activities will be disabled course-wide.',
    examples: [uncheckedExample, checkedExample],
  },
  {
    prop: 'isICPairUpDisabled',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'IC PairUp Disabled?', // Primary
    ],
    description: 'If checked, IC PairUp activities will be disabled course-wide.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'isSectionEvent',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'Is Section?', // Primary
    ],
    description: 'If checked, the event will be created as a section event.',
    examples: [uncheckedExample, checkedExample],
  },

  /* ----------------------- Output Columns ----------------------- */

  {
    prop: 'zoomPassword',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'Class Password', // Primary
    ],
    description: 'The password for the event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'zoomId',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'Class Zoom ID', // Primary
    ],
    description: 'The zoom id (meeting id/webinar id) for the event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'openZoomLink',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'Class Zoom Link', // Primary
    ],
    description: 'A link to join the Zoom event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'gatherLink',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'Class Gather Link', // Primary
    ],
    description: 'A link to open Gather for this event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'canvasLink',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'Canvas Course Link', // Primary
    ],
    description: 'A link to the Canvas course.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'results',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'Gather Class Results', // Primary
    ],
    description: 'Results of the batch creation process.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
];

export default COLUMN_HEADERS;
